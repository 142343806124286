var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-1 bg-white md:p-5"},[_c('div',{staticClass:"flex flex-row flex-wrap w-2/3 gap-8 pt-24 mx-auto mt-24 md:gap-32 lg:pt-32"},[_c('div',{staticClass:"w-full md:w-1/2"},[_c('h1',{staticClass:"text-left titulo-pagina"},[_vm._v("estemos en contacto")]),_c('ul',{staticClass:"flex flex-col mt-4"},[_c('li',{staticClass:"relative block p-4 -mb-px text-left border rounded-t border-grey"},[_c('font-awesome-icon',{attrs:{"icon":"envelope"}}),_c('a',{staticClass:"text-xs lg:text-sm",attrs:{"href":"mailto: info@estudiofdlopez.com.ar"}},[_vm._v(" info@estudiofdlopez.com.ar")])],1),_c('li',{staticClass:"relative block p-4 -mb-px text-left border border-grey"},[_c('font-awesome-icon',{attrs:{"icon":"phone-alt"}}),_c('a',{attrs:{"href":"tel:+541142183603"}},[_vm._v(" (+54)11 4218-3603")])],1),_c('li',{staticClass:"relative block p-4 -mb-px text-left border border-grey"},[_c('strong',[_vm._v("WhatsApp: ")]),_c('font-awesome-icon',{attrs:{"icon":"fa-whatsapp"}}),_c('a',{staticClass:"text-sm",attrs:{"href":"https://api.whatsapp.com/send?phone=541164643125&text=Hola%20quisiera%20realizar%20una%20consulta"}},[_vm._v(" (54)11 6464-3125 ")])],1),_c('li',{staticClass:"relative block p-4 -mb-px text-left border border-grey"},[_c('font-awesome-icon',{attrs:{"icon":"map-marked-alt"}}),_vm._m(0)],1)])]),_c('div',{staticClass:"w-full text-left md:w-1/3"},[_c('FormulateForm',{on:{"submit":_vm.sendEmail},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('FormulateInput',{attrs:{"type":"text","name":"nombre","label":"cuál es tu nombre(*)","label-class":"uppercase","placeholder":"ej: Carlos Gutierrez","validation":"required","validation-messages":{
            required: 'El Nombre es requerido',
          }}}),_c('FormulateInput',{attrs:{"type":"email","name":"email","label":"cuál es tu email(*)","label-class":"uppercase","placeholder":"ej: carlos@ejemplo.com","validation":"required","validation-messages":{
            required: 'El E-Mail es requerido',
          }}}),_c('FormulateInput',{attrs:{"type":"tel","name":"telefono","label":"cuál es tu teléfono","label-class":"uppercase","placeholder":"ej: +54 11 5637-5959"}}),_c('FormulateInput',{attrs:{"type":"textarea","name":"mensaje","label":"dejame tu mensaje o consulta(*)","label-class":"uppercase","validation":"required|max:50,length","validation-name":"tweet","help":`Solo quedan ${250 - _vm.value.length} caractéres. `,"validation-messages":{
            required: 'El Mensaje es requerido',
          }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('FormulateInput',{attrs:{"type":"submit","name":"enviar","input-class":"w-full py-2 font-bold text-white uppercase bg-green-400 rounded"}})],1)],1)]),_c('div')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://goo.gl/maps/7YmEurwCKYGEoYDL7"}},[_vm._v(" Alfredo Palacios 3312 - Lanús - "),_c('abbr',{attrs:{"title":"Provincia"}},[_vm._v("Prov.")]),_vm._v(" Buenos Aires ")])
}]

export { render, staticRenderFns }