<template>
  <div class="grid grid-cols-1 bg-white md:p-5">
    <div
      class="flex flex-row flex-wrap w-2/3 gap-8 pt-24 mx-auto mt-24 md:gap-32 lg:pt-32"
    >
      <div class="w-full md:w-1/2">
        <h1 class="text-left titulo-pagina">estemos en contacto</h1>
        <ul class="flex flex-col mt-4">
          <li
            class="relative block p-4 -mb-px text-left border rounded-t border-grey"
          >
            <font-awesome-icon icon="envelope" />
            <a class="text-xs lg:text-sm" href="mailto: info@estudiofdlopez.com.ar"
              >&nbsp;info@estudiofdlopez.com.ar</a
            >
          </li>
          <li class="relative block p-4 -mb-px text-left border border-grey">
            <font-awesome-icon icon="phone-alt" />
            <a href="tel:+541142183603">&nbsp;(+54)11 4218-3603</a>
          </li>
          <li class="relative block p-4 -mb-px text-left border border-grey">
            <strong>WhatsApp:&nbsp;</strong>
            <font-awesome-icon icon="fa-whatsapp"></font-awesome-icon>
            <a
              href="https://api.whatsapp.com/send?phone=541164643125&text=Hola%20quisiera%20realizar%20una%20consulta"
              class="text-sm"
            >
              (54)11 6464-3125
            </a>
          </li>
          <li class="relative block p-4 -mb-px text-left border border-grey">
            <font-awesome-icon icon="map-marked-alt" />
            <a href="https://goo.gl/maps/7YmEurwCKYGEoYDL7">
              Alfredo Palacios 3312 - Lanús -
              <abbr title="Provincia">Prov.</abbr> Buenos Aires
            </a>
          </li>
        </ul>
      </div>
      <div class="w-full text-left md:w-1/3">
        <FormulateForm v-model="values" @submit="sendEmail">
          <FormulateInput
            type="text"
            name="nombre"
            label="cuál es tu nombre(*)"
            label-class="uppercase"
            placeholder="ej: Carlos Gutierrez"
            validation="required"
            :validation-messages="{
              required: 'El Nombre es requerido',
            }"
          />

          <FormulateInput
            type="email"
            name="email"
            label="cuál es tu email(*)"
            label-class="uppercase"
            placeholder="ej: carlos@ejemplo.com"
            validation="required"
            :validation-messages="{
              required: 'El E-Mail es requerido',
            }"
          />

          <FormulateInput
            type="tel"
            name="telefono"
            label="cuál es tu teléfono"
            label-class="uppercase"
            placeholder="ej: +54 11 5637-5959"
          />

          <FormulateInput
            type="textarea"
            v-model="value"
            name="mensaje"
            label="dejame tu mensaje o consulta(*)"
            label-class="uppercase"
            validation="required|max:50,length"
            validation-name="tweet"
            :help="`Solo quedan ${250 - value.length} caractéres. `"
            :validation-messages="{
              required: 'El Mensaje es requerido',
            }"
          />

          <FormulateInput
            type="submit"
            name="enviar"
            input-class="w-full py-2 font-bold text-white uppercase bg-green-400 rounded"
          />
        </FormulateForm>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "Contacto",
  data() {
    return {
      values: null,
      value: "",
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .send(
          "service_7lsnt28",
          "template_4tax8n8",
          this.values,
          "fC0xER6Vz9r3UoRfJ"
        )
        .then((result) => {
          this.$toastr.success(
            "Su mensaje fue enviado estaremos en contacto",
            "Mensaje enviado"
          );
          console.log("Exito", result.status);
          this.values = null;
        })
        .catch((error) => {
          this.$toastr.error(error, "Mensaje enviado");
          console.error(error);
        });
    },
  },
};
</script>
